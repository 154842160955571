<!-- =========================================================================================
  File Name: projects.vue
  Description: All Projects list
  ----------------------------------------------------------------------------------------
  Item Name: KOZO Admin - VueJS Dashboard
  Author:
  Author URL:
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <vx-card class="form-title mb-6 vx-card-np" title="Select Form Type">
      <!-- <p>mouID : {{formMouId}}</p>
      <p>flaID : {{formFlaId}}</p>-->
      <div class="vx-row mx-0">
        <div class="vx-col w-full">
          <vs-tabs v-model="tabIndex">
            <vs-tab label="CONTRACT SERVICE" icon-pack="feather" icon="icon-box">
              <div class="tab-text">
                <!-- CONTRACT SERVICE FORM -->
                <div>
                  <contract-forms
                    :formMouId="formMouId"
                    :duplicate="duplicate"
                    :draft_id="draft_id"
                  ></contract-forms>
                </div>
              </div>
            </vs-tab>
            <vs-tab label="FLA FORM" icon-pack="feather" icon="icon-box">
              <!-- LFA FORMS -->
              <div>
                <contract-form-fla
                  :formFlaId="formFlaId"
                  :duplicate="duplicate"
                  :draft_id_fla="draft_id_fla"
                ></contract-form-fla>
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </div>
      <!-- <p>
        formMouId {{formMouId}}
        <span>condition</span>
        {{formMouId && !formFlaId}}
      </p>

      <p>
        formFlaId {{formFlaId}}
        <span>condition</span>
        {{formFlaId && !formMouId}}
      </p>-->
    </vx-card>
    <!-- <contract-form-comments v-if="formMouId || !formFlaId" :formMouId="formMouId"></contract-form-comments>
    <fla-contract-form-comments v-if="formFlaId" :formFlaId="formFlaId"></fla-contract-form-comments>-->
  </div>
</template>

<script>
import contractForms from "@/views/apps/contract/contractForms.vue";
import contractFormFla from "@/views/apps/contract/contractFormFla.vue";
import contractFormComments from "@/views/apps/contract/contractFormComments.vue";
import flaContractFormComments from "@/views/apps/contract/flaContractFormComments.vue";

export default {
  data() {
    return {
      isActive: false,
      tabIndex: 0,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      formMouId: 0,
      formFlaId: 0,
      duplicate: 0,
      draft_id: 0,
      draft_id_fla: 0
    };
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    }
  },
  components: {
    contractForms,
    contractFormFla,
    // contractFormListView,
    contractFormComments,
    flaContractFormComments
  },
  mounted() {
    eventBus.$on("switchFormEditTab", data => {
      this.formMouId = data.ID;
      this.tabIndex = data.tabIndex;
      if (data.hasOwnProperty("duplicate")) {
        this.duplicate = 1;
      }
      if (data.hasOwnProperty("draft_id")) {
        this.draft_id = data.draft_id;
      }
    });
    eventBus.$on("switchFormEditTabFla", data => {
      this.formFlaId = data.ID;
      this.tabIndex = data.tabIndex;
      if (data.hasOwnProperty("duplicate")) {
        this.duplicate = 1;
      }
      if (data.hasOwnProperty("draft_id_fla")) {
        this.draft_id_fla = data.draft_id_fla;
      }
    });
    eventBus.$on("switchTabMoudestroyThen", data => {
      this.formMouId = data;
      this.draft_id = 0;
    });
    eventBus.$on("switchTabFladestroyThen", data => {
      this.formFlaId = data;
      this.draft_id_fla = 0;
    });
  }
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
.con-vs-tabs .con-ul-tabs,
.vs-tabs--ul {
  background-color: #fff !important;
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul {
  background-color: #10163a !important;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul span {
  color: #c2c6dc;
  font-weight: bold;
}
</style>
