<template>
  <div>
    <!-- CONTRACT FORM  -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="form-title mt-4">
          <!-- <h4>Select Form Type</h4> -->
          <div class="row"></div>
        </div>

        <div class="form-title">
          <h4>Contract Form</h4>
          <form>
            <!-- 1ST ROW -->
            <div class="row">
              <div class="col-4 mb-2 mx-auto">
                <h6>Contract Form Type</h6>
                <multiselect
                  label="name"
                  class="w-full"
                  track-by="id"
                  v-model="form.typeOfAgreement"
                  name="contract Form"
                  :options="formType"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  :disabled="disabledAllForm"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.name }}
                  </template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vender Name')"
                    >{{ errors.first("Vender Name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Vender Name as per contract</h6>
                <multiselect
                  label="vendor_name"
                  class="w-full"
                  value="vendor_id"
                  v-model="form.vendorValue"
                  name="Vender Name"
                  :options="vendorList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  :disabled="disabledAllForm"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.vendor_name }}
                  </template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Vender Name')"
                    >{{ errors.first("Vender Name") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Project Name</h6>
                <multiselect
                  label="project_name"
                  class="w-full"
                  value="project_id"
                  v-model="form.projectName"
                  @select="onProjectSelect"
                  name="Project Name"
                  :options="projectList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  :disabled="disabledAllForm"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.project_name }}
                  </template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                    >{{ errors.first("Project Name") }}</span
                  >
                </p>
              </div>

              <!-- <div class="col-4 mb-2">
                <h6>Project Name</h6>
                <vs-input
                  type="text"
                  name="Project Name"
                  v-model="form.projectName"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                  >{{ errors.first("Project Name") }}</span>
                </p>
              </div>-->
            </div>

            <!-- 2ND ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Production House</h6>
                <multiselect
                  label="company_name"
                  class="w-full"
                  track-by="company_id"
                  v-model="form.companyValue"
                  name="Company Name"
                  :options="companyList"
                  :searchable="true"
                  :disabled="disabledAllForm"
                  open-direction="bottom"
                  :allow-empty="false"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.company_name }}
                  </template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Company Name')"
                    >{{ errors.first("Company Name") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Category</h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  @select="onSuggestSelect"
                  v-model="form.categoryValue"
                  name="Category Name"
                  :options="categoryList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  :disabled="categoryList.length == 0 ? true : disabledAllForm"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.category_name }}
                  </template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Category Name')"
                    >{{ errors.first("Category Name") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Subcategory</h6>
                <multiselect
                  label="category_name"
                  class="w-full"
                  track-by="category_id"
                  v-model="form.subcategoryValue"
                  name="Subcategory Name"
                  :options="subcategoryList"
                  :searchable="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  :disabled="
                    subcategoryList.length == 0 ? true : disabledAllForm
                  "
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.category_name }}
                  </template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Subcategory Name')"
                    >{{ errors.first("Subcategory Name") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 3RD ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Priority</h6>
                <multiselect
                  label="name"
                  class="w-full"
                  track-by="value"
                  v-model="form.priorityValue"
                  name="Priority"
                  :options="priority"
                  :disabled="disabledAllForm"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.name }}
                  </template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Priority')"
                    >{{ errors.first("Priority") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Type of project</h6>
                <vs-input
                  type="text"
                  name="Type of project"
                  v-model="form.projectTypeValue"
                  :disabled="disabledAllForm"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Type of project')"
                    >{{ errors.first("Type of project") }}</span
                  >
                </p>
              </div>
              <!-- <div class="col-4 mb-2">
                <h6>Type of project</h6>
                <multiselect
                  label="project_type_name"
                  class="w-full"
                  track-by="project_type_id"
                  v-model="form.projectTypeValue"
                  name="Type of project"
                  :options="ProjectTypeList"
                  :searchable="false"
                  :allow-empty="false"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{
                    option.project_type_name
                    }}
                  </template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Type of project')"
                  >{{ errors.first("Type of project") }}</span>
                </p>
              </div>-->
              <div class="col-4 mb-2">
                <h6>Type of show</h6>
                <vs-input
                  type="text"
                  name="Type of show"
                  v-model="form.showTypeValue"
                  :disabled="disabledAllForm"
                  class="w-full"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Type of show')"
                    >{{ errors.first("Type of show") }}</span
                  >
                </p>
              </div>
              <!-- <div class="col-4 mb-2">
                <h6>Type of show</h6>
                <multiselect
                  label="show_type_name"
                  class="w-full"
                  track-by="show_type_id"
                  v-model="form.showTypeValue"
                  name="Type of show"
                  :options="showTypeList"
                  :searchable="false"
                  :allow-empty="false"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{
                    option.show_type_name
                    }}
                  </template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Type of show')"
                  >{{ errors.first("Type of show") }}</span>
                </p>
              </div>-->
            </div>

            <!-- 4TH ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Current Status</h6>
                <multiselect
                  label="status_name"
                  class="w-full"
                  track-by="status_id"
                  v-model="form.ContractStatusValue"
                  name="Current Status"
                  :options="ContractStatusList"
                  :disabled="disabledAllForm"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.status_name }}
                  </template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Current Status')"
                    >{{ errors.first("Current Status") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Start Date</h6>
                <datepicker
                  placeholder="From Date"
                  :disabled="disabledAllForm"
                  format="dd-MM-yyyy"
                  v-model="form.dateFrom"
                ></datepicker>
              </div>

              <div class="col-4 mb-2">
                <h6>End Date</h6>
                <datepicker
                  placeholder="To Date"
                  :disabled="disabledAllForm"
                  v-model="form.dateTo"
                  format="dd-MM-yyyy"
                ></datepicker>
              </div>
            </div>

            <!-- 5TH ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Language</h6>
                <multiselect
                  label="language_name"
                  class="w-full"
                  track-by="language_id"
                  :multiple="true"
                  v-model="form.languageValue"
                  name="Language"
                  :options="languageList"
                  :disabled="disabledAllForm"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.language_name }}
                  </template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Language')"
                    >{{ errors.first("Language") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Directors</h6>
                <vs-input
                  name="Directors"
                  type="text"
                  class="w-full"
                  v-model="form.directorName"
                  :disabled="disabledAllForm"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Directors')"
                    >{{ errors.first("Directors") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Writer</h6>
                <vs-input
                  v-model="form.writerName"
                  type="text"
                  name="Writer"
                  class="w-full"
                  :disabled="disabledAllForm"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Writer')"
                    >{{ errors.first("Writer") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 6TH ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Internal CD</h6>
                <vs-input
                  type="text"
                  name="Internal CD"
                  class="w-full"
                  v-model="form.internalCD"
                  :disabled="disabledAllForm"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Internal CD')"
                    >{{ errors.first("Internal CD") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Lead cast</h6>
                <vs-input
                  type="text"
                  name="Lead cast"
                  class="w-full"
                  v-model="form.leadCast"
                  :disabled="disabledAllForm"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Lead cast')"
                    >{{ errors.first("Lead cast") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Nos. of Episodes</h6>
                <vs-input
                  v-model="form.episodes"
                  :disabled="disabledAllForm"
                  name="Nos. of Episodes"
                  class="w-full"
                  type="number"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Nos. of Episodes')"
                    >{{ errors.first("Nos. of Episodes") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Duration of the project</h6>
                <vs-input
                  name="Duration of the project"
                  class="w-full"
                  :disabled="disabledAllForm"
                  type="number"
                  v-model="form.projectDuration"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Duration')"
                    >{{ errors.first("Project Durations") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Shoot Days</h6>
                <vs-input
                  v-model="form.shootDays"
                  name="Shoot Days"
                  class="w-full"
                  type="number"
                  :disabled="disabledAllForm"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Shoot Days')"
                    >{{ errors.first("Shoot Days") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Platform</h6>
                <multiselect
                  label="platform_name"
                  class="w-full"
                  track-by="platform_id"
                  :multiple="true"
                  v-model="form.contractPlatformValue"
                  name="Platform Name"
                  :options="platformList"
                  :searchable="false"
                  :allow-empty="false"
                  open-direction="bottom"
                  placeholder="Select Option"
                  :disabled="disabledAllForm"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.platform_name }}
                  </template>
                </multiselect>
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Platform Name')"
                    >{{ errors.first("Platform Name") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 7TH ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Release Date</h6>
                <datepicker
                  placeholder="Release Date"
                  :disabled="disabledAllForm"
                  format="dd-MM-yyyy"
                  v-model="form.releaseDate"
                ></datepicker>
              </div>

              <div class="col-4 mb-2">
                <h6>Platform Upload Date</h6>
                <datepicker
                  placeholder="Platform Upload Date"
                  :disabled="disabledAllForm"
                  format="dd-MM-yyyy"
                  v-model="form.platformUploadDate"
                ></datepicker>
              </div>
            </div>

            <!-- 8TH ROW -->
            <div class="row">
              <!-- <div class="col-4 mb-2">
                <h6>INR Rate</h6>
                <vs-input
                  name="INR Rate"
                  v-model="form.inrRate"
                  class="w-full"
                  type="number"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Rate')"
                    >{{ errors.first("Rate") }}</span
                  >
                </p>
              </div>-->

              <!-- <div class="col-4 mb-2">
                <h6>Per Episode Cost</h6>
                <vs-input
                  v-model="form.episodeCost"
                  name="Per Episode Cost"
                  class="w-full"
                  type="number"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Episode Cost')"
                    >{{ errors.first("Episode Cost") }}</span
                  >
                </p>
              </div>-->

              <div class="col-4 mb-2">
                <h6>Budget Amount</h6>
                <vs-input
                  v-model="form.budgetAmt"
                  name="Budget Amount"
                  :disabled="disabledAllForm"
                  class="w-full"
                  type="number"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Budget Amount')"
                    >{{ errors.first("Budget Amount") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Contract Value</h6>
                <vs-input
                  v-model="form.contractValue"
                  name="Contract Value"
                  class="w-full"
                  :disabled="disabledAllForm"
                  type="number"
                  v-on:keyup="getBalanceAmount()"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Contract Value')"
                    >{{ errors.first("Contract Value") }}</span
                  >
                </p>
              </div>

              <div class="col-4 mb-2">
                <h6>Incremantal cost</h6>
                <vs-input
                  name="Incremantal cost"
                  v-model="form.incremantalCost"
                  :disabled="disabledAllForm"
                  class="w-full"
                  type="number"
                  v-on:keyup="getBalanceAmount()"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Incremantal Cost')"
                    >{{ errors.first("Incremantal Cost") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 9TH ROW -->
            <div class="row">
              <div class="col-4 mb-2">
                <h6>Paid Till Now</h6>
                <vs-input
                  v-model="form.paid"
                  name="Paid Till Now"
                  class="w-full"
                  type="number"
                  v-on:keyup="getBalanceAmount()"
                  :disabled="true"
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Paid Till Now')"
                    >{{ errors.first("Paid Till Now") }}</span
                  >
                </p>
              </div>
              <div class="col-4 mb-2">
                <h6>Balance Amount</h6>
                <vs-input
                  v-model="form.balanceAmount"
                  name="Balance Amount"
                  class="w-full font-weight-bold"
                  type="number"
                  disabled
                />
                <p class="error-msg text-sm">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Balance Amount')"
                    >{{ errors.first("Balance Amount") }}</span
                  >
                </p>
              </div>
            </div>

            <!-- 10TH ROW -->
            <!-- <div class="row"></div> -->
          </form>

          <!-- OPTION DATE TABLE FORM -->
          <div class="contract-Table mb-5">
            <h6>Deliverables</h6>
            <b-table
              :sticky-header="stickyHeader"
              :no-border-collapse="noCollapse"
              responsive
              :items="optionItems"
              :fields="optionDatafields"
            >
              <!-- We are using utility class `text-nowrap` to help illustrate horizontal scrolling -->
              <!-- Option Description -->
              <template v-slot:head(add)="data" v-if="!disabledAllForm">
                <span
                  class="text-center table-edit-icon"
                  @click="addOptionData('optionDateData')"
                >
                  <i class="text-center material-icons bg-transparent">add</i>
                </span>
              </template>

              <template v-slot:cell(add)="data" v-if="!disabledAllForm">
                <span
                  v-if="!data.item.hasOwnProperty('option_id')"
                  class="text-center table-edit-icon"
                  @click="removeOptionData(data.index, 'optionDateData')"
                >
                  <i class="text-center material-icons bg-transparent"
                    >remove</i
                  >
                </span>
              </template>

              <!-- Option Description -->
              <template v-slot:cell(option_description)="data">
                <vs-input
                  name="option description"
                  :disabled="
                    !disabledAllForm
                      ? false
                      : data.item.optionShow
                      ? false
                      : true
                  "
                  v-model="optionItems[data.index].option_description"
                  class="w-full asterisk-icon"
                  :class="
                    !optionItems[data.index].option_description ? 'sign' : ''
                  "
                />
              </template>

              <!-- Schedule Date -->
              <template v-slot:cell(schedule_date)="data">
                <input
                  type="date"
                  v-model="optionItems[data.index].schedule_date"
                  :disabled="
                    !disabledAllForm
                      ? false
                      : data.item.optionShow
                      ? false
                      : true
                  "
                  class="asterisk-icon dateInput"
                  :class="!optionItems[data.index].schedule_date ? 'sign' : ''"
                />
              </template>

              <!-- Actual Date -->
              <template v-slot:cell(actual_date)="data">
                <input
                  type="date"
                  v-model="optionItems[data.index].actual_date"
                  class="asterisk-icon dateInput"
                  :disabled="
                    !disabledAllForm
                      ? false
                      : data.item.optionShow
                      ? false
                      : true
                  "
                  :class="!optionItems[data.index].actual_date ? 'sign' : ''"
                />
              </template>

              <!-- Deliverables Status -->
              <template v-slot:cell(option_status)="data">
                <b-form-checkbox
                  @change="
                    onChangeCheckStatus(
                      optionItems[data.index].option_status,
                      data.index
                    )
                  "
                  value="1"
                  unchecked-value="2"
                  v-model="optionItems[data.index].option_status"
                  :disabled="
                    !disabledAllForm
                      ? false
                      : data.item.optionShow
                      ? false
                      : true
                  "
                  switch
                  class="table-status-icon pt-0 pb-2"
                ></b-form-checkbox>
              </template>

              <!-- Reminder Type -->
              <template v-slot:cell(reminder_type)="data">
                <vs-select
                  class="selectExample asterisk-icon"
                  placeholder="select option"
                  :disabled="
                    !disabledAllForm
                      ? false
                      : data.item.optionShow
                      ? false
                      : true
                  "
                  :class="!optionItems[data.index].reminder_type ? 'sign' : ''"
                  v-model="optionItems[data.index].reminder_type"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.reminder_type_id"
                    :text="item.reminder_type_name"
                    v-for="(item, index) in ReminderTypeList"
                    :disabled="
                      !disabledAllForm
                        ? false
                        : data.item.optionShow
                        ? false
                        : true
                    "
                  />
                </vs-select>
              </template>

              <!-- Assignee -->
              <template v-slot:cell(assignee)="data">
                <div class="position-relative">
                  <vs-input
                    name="Assignee"
                    v-model="optionItems[data.index].assignee.length"
                    disabled
                    class="w-full overflow-hidden asterisk-icon assignee"
                    :class="!optionItems[data.index].assignee ? 'sign' : ''"
                  />
                  <span class="assigneeAddBtn" v-if="!disabledAllForm">
                    <i
                      class="text-center material-icons bg-transparent"
                      @click="optionAddAssignee(data.index)"
                      >add</i
                    >
                  </span>
                </div>
              </template>
            </b-table>
          </div>

          <!-- MILESTONE TABLE FORM -->
          <div class="contract-Table mb-5">
            <h6>
              Milestone:
              <span style="color: red">{{ errorValueAmount }}</span>
            </h6>
            <b-table
              :sticky-header="stickyHeader"
              :no-border-collapse="noCollapse"
              responsive
              :items="milestoneItems"
              :fields="milestoneFields"
            >
              <!-- We are using utility class `text-nowrap` to help illustrate horizontal scrolling -->
              <!-- Option Description -->

              <template
                v-slot:head(add)="data"
                v-if="!disabledAllForm && errorValueAmount == ''"
              >
                <span
                  class="text-center table-edit-icon"
                  @click="addOptionData('milestoneData')"
                >
                  <i class="text-center material-icons bg-transparent">add</i>
                </span>
              </template>

              <template v-slot:cell(add)="data" v-if="!disabledAllForm">
                <span
                  class="text-center table-edit-icon"
                  v-if="
                    !data.item.hasOwnProperty('milestone_id') && data.index > 0
                  "
                  @click="removeOptionData(data.index, 'milestoneData')"
                >
                  <i class="text-center material-icons bg-transparent"
                    >remove</i
                  >
                </span>
              </template>

              <template v-slot:cell(contract_description)="data">
                <vs-input
                  name="Contract Description"
                  v-model="milestoneItems[data.index].contract_description"
                  :disabled="
                    !disabledAllForm
                      ? false
                      : data.item.milestoneShow
                      ? false
                      : true
                  "
                  class="w-full asterisk-icon"
                  :class="
                    !milestoneItems[data.index].contract_description
                      ? 'sign'
                      : ''
                  "
                />
              </template>

              <template v-slot:cell(value_number)="data">
                <vs-input
                  name="Value Number"
                  type="number"
                  :disabled="
                    !disabledAllForm
                      ? false
                      : data.item.milestoneShow
                      ? false
                      : true
                  "
                  v-on:keyup="getValueNumberAmount(data.index)"
                  v-model="milestoneItems[data.index].value_number"
                  class="w-full asterisk-icon font-bold"
                  :class="
                    !milestoneItems[data.index].value_number ? 'sign' : ''
                  "
                />
              </template>

              <!-- value % -->
              <template v-slot:cell(value)="data">
                <vs-input
                  name="value"
                  type="number"
                  disabled
                  v-model="milestoneItems[data.index].value"
                  class="w-full asterisk-icon"
                  :class="!milestoneItems[data.index].value ? 'sign' : ''"
                />
              </template>

              <!-- Value Number -->

              <!-- Paid Amount -->
              <template v-slot:cell(paid_amount)="data">
                <vs-input
                  name
                  type="number"
                  v-on:keyup="getBalanceAmount(data.index)"
                  v-model="milestoneItems[data.index].paid_amount"
                  :disabled="
                    !disabledAllForm
                      ? false
                      : data.item.milestoneShow
                      ? false
                      : true
                  "
                  class="w-full asterisk-icon"
                />
              </template>

              <!-- Due Date -->
              <template v-slot:cell(due_date)="data">
                <input
                  type="date"
                  v-model="milestoneItems[data.index].due_date"
                  :disabled="
                    !disabledAllForm
                      ? false
                      : data.item.milestoneShow
                      ? false
                      : true
                  "
                  class="asterisk-icon dateInput"
                  :class="!milestoneItems[data.index].due_date ? 'sign' : ''"
                />
              </template>

              <!-- Reminder Type -->
              <template v-slot:cell(reminder_type)="data">
                <vs-select
                  class="selectExample asterisk-icon"
                  placeholder="select option"
                  :class="
                    !milestoneItems[data.index].reminder_type ? 'sign' : ''
                  "
                  v-model="milestoneItems[data.index].reminder_type"
                  :disabled="
                    !disabledAllForm
                      ? false
                      : data.item.milestoneShow
                      ? false
                      : true
                  "
                >
                  <vs-select-item
                    :key="index"
                    :value="item.reminder_type_id"
                    :text="item.reminder_type_name"
                    v-for="(item, index) in ReminderTypeList"
                    :disabled="
                      !disabledAllForm
                        ? false
                        : data.item.milestoneShow
                        ? false
                        : true
                    "
                  />
                </vs-select>
              </template>

              <!-- Reminder -->
              <!-- <template v-slot:cell(reminder)="data">
                <vs-input
                  name
                  v-model="milestoneItems[data.index].reminder"
                  class="asterisk-icon"
                  :class="!milestoneItems[data.index].reminder ? 'sign' : ''"
                />
              </template>-->

              <!-- Assignee -->
              <template v-slot:cell(assignee)="data">
                <div class="position-relative">
                  <vs-input
                    name="Assignee"
                    v-model="milestoneItems[data.index].assignee.length"
                    disabled
                    class="w-full overflow-hidden asterisk-icon assignee"
                    :class="!milestoneItems[data.index].assignee ? 'sign' : ''"
                  />
                  <span class="assigneeAddBtn" v-if="!disabledAllForm">
                    <i
                      class="text-center material-icons bg-transparent"
                      @click="addAssignee(data.index)"
                      >add</i
                    >
                  </span>
                </div>
              </template>
            </b-table>
          </div>

          <!-- EDITOR -->
          <div class="mt-5 Editer">
            <h6>Description</h6>
            <quill-editor
              v-model="form.content"
              :disabled="disabledAllForm"
            ></quill-editor>
          </div>

          <!-- UPLOAD SECTION -->
          <div class="doc-upload mt-5" v-if="!disabledAllForm">
            <h6>Upload Document</h6>
            <span class="float-right mt-3 mb-5">
              <vs-button
                v-if="
                  (this.formMouId > 0 || this.draft_id > 0) &&
                  isDocuments.length > 0
                "
                color="success"
                type="filled"
                @click="getAllUplodeFiles()"
                :disabled="disabledAllForm"
                class="mr-3"
                >See All Files</vs-button
              >
            </span>
            <vue-dropzone
              @vdropzone-error-multiple="vdropzoneError"
              @vdropzone-complete-multiple="vdropzoneComplete"
              @vdropzone-added-files="vdropzoneFilesAdded"
              @vdropzone-success-multiple="vdropzoneSuccessFile"
              ref="myVueDropzoneFile"
              id="dropzone"
              class="rounded"
              :options="dropzoneOptionsFiles"
            ></vue-dropzone>
            <vs-button
              v-if="fileUpload.deleteButtonStatus"
              type="filled"
              @click.prevent="clearFile()"
              class="mr-3 feather icon-trash"
            />
          </div>

          <!-- USER LEVEL-->
          <div class="mt-5" v-if="form.showHierarchy && !disabledAllForm">
            <h6>User Level</h6>
            <div class="px-5">
              <div class="vx-row mx-0 px-5">
                <ul
                  class="activity-timeline"
                  :style="formMouId == 0 || duplicate > 0 ? '' : 'border:none'"
                >
                  <li
                    class
                    v-for="(level, levelIndex) in form.hierachyArray"
                    :key="levelIndex"
                    v-if="
                      form.hierachyArray.length > 0 &&
                      level.user_list.filter((x) => x.mou_map_isActive == 1)
                        .length > 0
                    "
                  >
                    <div class="timeline-icon bg-success">
                      <span class="font-bold text-white"
                        >Level {{ level.user_level }}</span
                      >
                    </div>

                    <div class="timeline-info">
                      <ul class="list-inline mb-0 d-flex flex-wrap">
                        <li
                          class="list-inline-item mb-3"
                          v-for="(singleUser, userIndex) in level.user_list"
                          :key="'user' + userIndex"
                          v-if="singleUser.mou_map_isActive == 1"
                        >
                          <div class="po-user-card px-3 rounded">
                            <!-- @click="onRemoveLevel(level, singleUser)" -->
                            <i
                              class="
                                text-center
                                material-icons
                                level-close-icon
                              "
                              @click="
                                removeUserFromLevel(levelIndex, userIndex)
                              "
                              v-if="
                                (level.user_level <=
                                  form.hierachyArray.length &&
                                  level.user_list.filter(
                                    (x) => x.mou_map_isActive == 1
                                  ).length > 1 &&
                                  (formMouId == 0 || duplicate > 0)) ||
                                (level.user_level ==
                                  form.hierachyArray.length &&
                                  (formMouId == 0 || duplicate > 0)) ||
                                (level.user_level <=
                                  form.hierachyArray.length &&
                                  level.user_list.filter(
                                    (x) => x.mou_map_isActive == 1
                                  ).length > 1 &&
                                  formMouId > 0 &&
                                  level.user_level <=
                                    form['activeHierarchyLevel'] &&
                                  form.hasOwnProperty('activeHierarchyLevel'))
                              "
                              >close</i
                            >

                            <h6 class="font-semibold mb-0 text-center">
                              {{ singleUser.user_name }}
                            </h6>
                          </div>
                        </li>
                        <!-- @click="openSelectUser(level)"  -->
                        <li
                          class="list-inline-item mb-0 ml-3"
                          v-if="
                            (formMouId > 0 &&
                              level.user_level <=
                                form['activeHierarchyLevel'] &&
                              form.hasOwnProperty('activeHierarchyLevel')) ||
                            formMouId == 0 ||
                            duplicate > 0
                          "
                        >
                          <div class="add-user-btn px-3 rounded">
                            <h6 class="font-semibold mb-0 text-center">
                              <span class="pluse_1"></span>
                              <i
                                class="text-center material-icons"
                                @click="addUserToLevel(level, levelIndex)"
                                >add_circle</i
                              >
                            </h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <!-- <small class="text-grey activity-e-time">25 mins ago</small> -->
                  </li>
                  <!-- @click="onAddNewLevel()" -->
                  <li
                    class="mb-base pb-5"
                    @click="addNewLevel"
                    v-if="formMouId == 0 || duplicate > 0"
                  >
                    <div class="add-level-icon">
                      <span class="font-bold text-white">
                        <i class="text-center material-icons align-middle"
                          >add_circle</i
                        >
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <!-- LEVEL POPUP USERS ADD -->
            <vs-popup
              :button-close-hidden="false"
              class="holamundo p-2 assigneePopup"
              :title="UserPopupTitle"
              :active.sync="addUserLevel"
            >
              <div class="text-center">
                <h5>Add User's</h5>
                <!-- SUBMIT BUTTON -->
                <div class="vx-row mt-5">
                  <div class="vx-col mx-auto w-100">
                    <multiselect
                      label="user_name"
                      class="w-full mb-5"
                      track-by="user_id"
                      v-model="userLevelArray"
                      :options="tempContractUserListArrayNewList"
                      :searchable="true"
                      :allow-empty="false"
                      :multiple="true"
                      open-direction="bottom"
                      placeholder="Select Option"
                      deselect-label="Can't remove this value"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.user_name }}
                      </template>
                    </multiselect>
                    <vs-button
                      color="success"
                      type="filled"
                      class="mr-3"
                      @click="addUserLevelMet"
                      >Add User</vs-button
                    >
                  </div>
                </div>
              </div>
            </vs-popup>

            <!-- REMOVE USER -->
            <vs-popup
              class="holamundo p-2"
              title="Remove User"
              :active.sync="removeUserPop"
            >
              <div class="text-center">
                <h5>
                  You want to remove {{ UserRemoveName }} from Level
                  {{ UserRemoveLevel }}
                </h5>
                <!-- SUBMIT BUTTON -->
                <div class="vx-row mx-auto mt-5">
                  <div class="vx-col mx-auto">
                    <vs-button
                      color="success"
                      type="filled"
                      @click="onChangeRmove($event)"
                      class="mr-3"
                      >Yes</vs-button
                    >

                    <vs-button
                      color="danger"
                      type="filled"
                      @click="privStatusLevel()"
                      >No</vs-button
                    >
                  </div>
                </div>
              </div>
            </vs-popup>
          </div>

          <!-- BUTTONS -->
          <div class="vx-col mx-auto text-center mt-5">
            <!-- @click.prevent="submitForm" -->
            <!-- :disabled="count.length != 2" -->
            <vs-button
              type="filled"
              class="hover-btn mr-3"
              v-if="formMouId == 0"
              :disabled="checkSaveAsDraft"
              @click="saveDraft"
              >Save As Draft</vs-button
            >
            <vs-button
              type="filled"
              class="hover-btn mr-3"
              @click.prevent="submitAndSave"
              :disabled="
                errorValueAmount == '' || errorValueAmount == ' '
                  ? checkSubmitStatus
                  : true
              "
              >Submit</vs-button
            >
            <!-- @click.prevent="clearForm" -->
            <vs-button
              color="warning"
              type="border"
              :class="formMouId ? 'mr-3' : ''"
              @click.prevent="resetForm"
              >Reset</vs-button
            >
            <vs-button
              type="filled"
              class="hover-btn"
              v-if="formMouId"
              @click.prevent="newForm"
              >New Form</vs-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- OPTION STATUS POPUP -->
    <vs-popup
      :button-close-hidden="true"
      class="holamundo p-2"
      title="Contract Form Row"
      :active.sync="popupActive"
    >
      <div class="text-center">
        <h5>Do you want to remove form row?</h5>
        <!-- SUBMIT BUTTON -->
        <div class="vx-row mt-5">
          <div class="vx-col mx-auto">
            <vs-button
              color="success"
              type="filled"
              @click="onChangeCheckbox($event)"
              class="mr-3"
              >Yes</vs-button
            >
            <vs-button color="danger" type="filled" @click="privStatus()"
              >No</vs-button
            >
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- OPTION ASSIGNEE POPUP -->
    <vs-popup
      :button-close-hidden="false"
      class="holamundo p-2 assigneePopup"
      title="Assignee"
      :active.sync="optionAssigneePopup"
    >
      <div class="text-center">
        <h5>Add multiple Assignee's</h5>
        <!-- SUBMIT BUTTON -->
        <div class="vx-row mt-5">
          <div class="vx-col mx-auto w-100">
            <multiselect
              label="user_name"
              class="w-full mb-5"
              track-by="user_id"
              v-model="optionArray"
              @remove="removeOptionList"
              name="contract Form"
              :options="ContractUserList"
              :searchable="true"
              :allow-empty="false"
              :multiple="true"
              open-direction="bottom"
              placeholder="Select Option"
              deselect-label="Can't remove this value"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.user_name }}
              </template>
            </multiselect>
            <vs-button
              color="success"
              type="filled"
              @click="onChangeCheckOptionAssignee($event)"
              class="mr-3"
              >Save</vs-button
            >
            <!-- <vs-button color="danger" type="filled" @click="assigneePrivStatus()">No</vs-button> -->
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- MILESTONE ASSIGNEE POPUP -->
    <div class>
      <vs-popup
        :button-close-hidden="false"
        class="holamundo p-2 assigneePopup"
        title="Assignee"
        :active.sync="addAssigneePopup"
      >
        <div class="text-center">
          <h5>Add multiple Assignee's</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto w-100">
              <multiselect
                label="user_name"
                class="w-full mb-5"
                track-by="user_id"
                v-model="mileStoneArray"
                name="contract Form"
                @remove="removeOptionList"
                :options="ContractUserList"
                :searchable="true"
                :allow-empty="false"
                :multiple="true"
                open-direction="bottom"
                placeholder="Select Option"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.user_name }}
                </template>
              </multiselect>
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckAssignee($event)"
                class="mr-3"
                >Save</vs-button
              >
              <!-- <vs-button color="danger" type="filled" @click="assigneePrivStatus()">No</vs-button> -->
            </div>
          </div>
        </div>
      </vs-popup>

      <!-- FINAL SAVE POPUP -->
      <vs-popup
        title="Save Changes"
        :active.sync="contractFormReason"
        button-close-hidden
      >
        <div class="text-center">
          <h5>Reason</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-textarea v-model="reason" class="mb-4" rows="4" />
              <vs-button
                color="success"
                type="filled"
                @click="submitForm"
                class="mr-3"
                >Submit</vs-button
              >
              <vs-button color="danger" @click="closeModalnew"
                >Cancel</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>

      <!-- UPLOADED FILES STATUS -->
      <vs-popup
        :button-close-hidden="false"
        class="holamundo p-2"
        title="Uploaded Files"
        :active.sync="uploadFilePopup"
      >
        <div class>
          <ul class>
            <li
              class="font-weight-bold mb-2 pb-2"
              :class="isDocuments.length > 1 ? 'border-bottom' : ''"
              v-for="(document, docIndex) in isDocuments"
              :key="docIndex"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div>Document {{ docIndex + 1 }}</div>
                <div>
                  <span class="float-right">
                    <vs-button
                      color="success"
                      type="filled"
                      @click="onChangeCheckUploadFile(document)"
                      class="mr-3"
                      >view</vs-button
                    >
                    <!-- <vs-button
                      color="danger"
                      type="filled"
                      @click="onChangeDeleteFile(docIndex)"
                    >Delete</vs-button>-->
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </vs-popup>

      <!-- DELIVERABLES STATUS MODAL -->
      <div class="demo-alignment">
        <vs-popup
          :button-close-hidden="true"
          class="holamundo p-2"
          title="Department Status"
          :active.sync="deliverablesStatus"
        >
          <div class="text-center">
            <h5>Do you want to change Status ?</h5>
            <!-- SUBMIT BUTTON -->
            <div class="vx-row mt-5">
              <div class="vx-col mx-auto">
                <!-- :disabled="submitStatus" -->
                <vs-button
                  color="success"
                  type="filled"
                  @click="onChangeOptionCheckStatus($event)"
                  class="mr-3"
                  >Yes</vs-button
                >

                <vs-button
                  color="danger"
                  type="filled"
                  @click="privDeliverablesStatus()"
                  >No</vs-button
                >
              </div>
            </div>
          </div>
        </vs-popup>
      </div>
    </div>

    <contract-form-comments
      v-if="formMouId && !form.hasOwnProperty('isDuplicate')"
      :formMouId="formMouId"
    ></contract-form-comments>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index";
import constant from "@/helper/constant.js";

import Datepicker from "vuejs-datepicker";
import vue2Dropzone from "vue2-dropzone";
import ContractService from "@/services/ContractService.js";
import CategoryService from "@/services/categoryService.js";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import contractFormComments from "@/views/apps/contract/contractFormComments.vue";

import { quillEditor } from "vue-quill-editor";
export default {
  props: {
    formMouId: Number,
    duplicate: Number,
    draft_id: Number,
  },
  components: {
    Datepicker,
    quillEditor,
    vueDropzone: vue2Dropzone,
    contractFormComments,
  },
  beforeMount() {
    this.dropzoneOptionsFiles["url"] =
      BASEURL() + "/v2/multipleUploads?organization_id=" + this.organization_id;
    this.dropzoneOptionsFiles["headers"]["authorization"] = this.access_token;
  },
  data() {
    return {
      // formMouId: 0,
      popupActive: false,
      contractFormReason: false,
      submitStatus: false,
      uploadFilePopup: false,
      addUserLevel: false,

      removeUserPop: false,
      deliverablesStatus: false,
      isDocuments: [],
      rowIndex: {},
      userLevelArray: [],
      companyValue: [],
      formTypeValue: "",
      vendorList: [],
      companyList: [],
      projectList: [],
      categoryList: [],
      subcategoryList: [],
      ProjectTypeList: [],
      showTypeList: [],
      ContractStatusList: [],
      languageList: [],
      ReminderTypeList: [],
      ContractUserList: [],
      valueagr: "",
      startDisabledDates: {
        from: new Date(),
      },
      endDisabledDates: {
        from: new Date(),
        to: null,
      },
      dropzoneOptionsFiles: {
        parallelUploads: 10,
        url: BASEURL() + "/v2/multipleUploads?organization=",
        ...constant.DEFAULT_FILES,
        acceptedFiles: ".pdf,.xls,.xlsx,.csv,.txt,.png,.jpg,.jpeg",
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: true,
        maxFiles: 10,
      },
      fileUpload: {
        file_url: "",
        deleteButtonStatus: false,
      },
      uploadedDocument: "-",
      //  ContractUserValue: "",
      projectObj: {
        directors: "directorName",
        episodes: "episodes",
        internal_cd: "internalCD",
        lead_cast: "leadCast",
        project_type: "projectTypeValue",

        shoot_days: "shootDays",
        show_type: "showTypeValue",
        writers: "writerName",
      },
      form: {
        mou_isactive: 1,
        typeOfAgreement: "",
        vendorValue: "",
        projectName: "",
        companyValue: "",
        mainFormShow: 1,
        releaseDate: "",
        contractPlatformValue: "",
        platformUploadDate: "",

        categoryValue: "",
        subcategoryValue: "",
        priorityValue: "",
        showHierarchy: true,
        projectTypeValue: "",
        showTypeValue: "",
        ContractStatusValue: "",

        dateFrom: "",
        dateTo: "",
        languageValue: "",

        directorName: "",
        writerName: "",
        internalCD: "",

        leadCast: "",
        episodes: "",
        projectDuration: "",

        shootDays: "",

        inrRate: 1,
        episodeCost: 1,
        budgetAmt: 0,

        contractValue: 0,
        incremantalCost: 0,
        paid: 0,

        balanceAmount: 0,

        content: "",
        hierachyArray: [],
        budget_id: 0,
      },
      reason: "",
      value: null,

      formType: [
        { name: "MOU", id: "1" },
        { name: "LFA", id: "2" },
      ],
      priority: [
        { name: "High", value: "1" },
        { name: "Medium", value: "2" },
        { name: "Low", value: "3" },
        { name: "None", value: "4" },
      ],
      options: [
        { name: "Test 1", language: "User 1" },
        { name: "Test 2", language: "User 2" },
        { name: "Test 3", language: "User 3" },
        { name: "Test 4", language: "User 4" },
      ],
      stickyHeader: true,
      noCollapse: true,
      addAssigneePopup: false,
      optionAssigneePopup: false,
      testOptionStatus: "",
      testOptionIndex: "",
      optionDatafields: [
        {
          key: "Add",
          label: "",
          sortable: true,
        },
        {
          key: "option_description",
          label: "Deliverables Description",
          sortable: true,
        },
        {
          key: "schedule_date",
          label: "Schedule Date",
          sortable: true,
        },
        {
          key: "actual_date",
          label: "Actual Date",
          sortable: true,
        },
        {
          key: "option_status",
          label: "Deliverables Status",
          sortable: true,
        },
        {
          key: "reminder_type",
          label: "Reminder Type",
          sortable: true,
        },
        {
          key: "assignee",
          label: "Assignee",
          sortable: true,
        },
      ],
      optionItems: [
        // {
        //   add: "1",
        //   option_description: "",
        //   schedule_date: "",
        //   actual_date: "",
        //   option_status: "1",
        //   reminder_type: "Select Option",
        //   assignee: [],
        //   optionShow: true
        // }
      ],
      milestoneFields: [
        {
          key: "Add",
          label: "",
          sortable: true,
        },
        {
          key: "contract_description",
          label: "Contract Description",
          sortable: true,
        },
        {
          key: "value_number",
          label: "Value",
          sortable: true,
        },
        {
          key: "value",
          label: "value %",
          sortable: true,
        },

        {
          key: "paid_amount",
          label: "Paid Amount",
          sortable: true,
        },
        {
          key: "due_date",
          label: "Due Date",
          sortable: true,
        },
        {
          key: "reminder_type",
          label: "Reminder Type",
          sortable: true,
        },

        {
          key: "assignee",
          label: "Assignee",
          sortable: true,
        },
      ],
      milestoneItems: [
        {
          add: "1",
          contract_description: "",
          value: null,
          value_number: null,
          paid_amount: 0,
          due_date: "",
          reminder_type: "Select Option",
          milestoneShow: true,
          //  reminder: 0,
          assignee: [],
        },
      ],
      milestoneItemsIndex: 0,
      optionItemsIndex: 0,
      mileStoneArray: [],
      optionArray: [],
      count: [],
      platformList: [],
      try: {},
      try1: {},

      // saveUserLevel: {
      //   user_level:1
      // }
      tempContractUserListArrayNew: [],
      removeOptionListArray: [],
      saveUserIndex: "",
      removeUserLevel1: 0,
      removeUserLevel2: 0,
      UserPopupTitle: "Level",
      UserRemoveName: "",
      UserRemoveLevel: "",
      MainUserLevel: 0,
      checkStatusEdit: true,
      newCheckCount: 0,
      checkFormLevel: 0,
      errorValueAmount: "",
      //1 for delievrable,2-for Milestone
    };
  },
  watch: {
    formMouId: function (newVal) {
      //  this.somevar = newValal
      // console.log("newVal :>> ", newVal);
    },
    form: {
      handler: function (newVal, oldVal) {
        if (this.optionItems.length == 0) {
          let i = this.count.indexOf("optionItems");
          if (i < 0) {
            this.count.push("optionItems");
          }
        }

        // console.log("this.count :>> ", this.count);
        let len = Object.keys(newVal).length;

        if (
          newVal.hasOwnProperty("activeHierarchyLevel") &&
          newVal.activeHierarchyLevel == 0
        ) {
          len = len - 1;
        }

        if (
          newVal.hasOwnProperty("linkingBudgetId") &&
          (newVal.linkingBudgetId == "" || newVal.linkingBudgetId == null)
        ) {
          len = len - 1;
        }

        if (
          newVal.hasOwnProperty("showHierarchy") &&
          newVal.showHierarchy == false
        ) {
          len = len - 1;
        }

        if (newVal.hasOwnProperty("paid") && newVal.paid == 0) {
          len = len - 1;
        }

        if (
          newVal.hasOwnProperty("balanceAmount") &&
          newVal.balanceAmount == 0
        ) {
          len = len - 1;
        }

        if (
          newVal.hasOwnProperty("contract_budgetactive") &&
          newVal.contract_budgetactive == 0
        ) {
          len = len - 1;
        }

        if (
          newVal.hasOwnProperty("incremantalCost") &&
          newVal.incremantalCost == 0
        ) {
          len = len - 1;
        }
        if (newVal.hasOwnProperty("budgetAmt") && newVal.budgetAmt == 0) {
          len = len - 1;
        }

        if (newVal.hasOwnProperty("budget_id") && newVal.budget_id == 0) {
          len = len - 1;
        }

        if (
          newVal.hasOwnProperty("status_budgetactive") &&
          newVal.status_budgetactive == 0
        ) {
          len = len - 1;
        }

        if (
          newVal.hasOwnProperty("optionItems") &&
          newVal.optionItems.length == 0
        ) {
          len = len - 1;
        }

        Object.keys(newVal).map((x, index) => {
          if (
            newVal[x] &&
            newVal[x] !== undefined &&
            String(newVal[x]).trim().length > 0
          ) {
          } else {
            console.log(x, newVal[x], Object.keys(newVal).length);
          }
        });
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0 &&
              newVal[x] != 0
          ).length
        ) {
          let checkIndex = this.count.indexOf("form");
          if (checkIndex < 0) {
            this.count.push("form");
            //  console.log("checkIndex :>> if ", checkIndex);
          }
        } else {
          let checkIndex = this.count.indexOf("form");
          if (checkIndex >= 0) {
            this.count.splice(checkIndex, 1);
          }
        }
        console.log(this.count);
      },
      deep: true,
    },
    milestoneItems: {
      handler: function (newVal, oldVal) {
        console.log("this.count :>> ", this.count);
        let countObj = 0;
        let amountPaild = 0;
        let value_amount_total = 0;
        let checkNew = 0;

        for (let i = 0; i < this.milestoneItems.length; i++) {
          let len = 0;

          if (
            this.milestoneItems[i].hasOwnProperty("milestoneShow") &&
            this.milestoneItems[i].milestoneShow == false
          ) {
            len = Object.keys(this.milestoneItems[i]).length - 1;
          } else {
            len = Object.keys(this.milestoneItems[i]).length;
          }

          if (
            this.milestoneItems[i].hasOwnProperty("paid_amount") &&
            (this.milestoneItems[i]["paid_amount"] <= 0 ||
              this.milestoneItems[i]["paid_amount"] == "")
          ) {
            len = len - 1;
          }

          if (
            len ===
            Object.keys(this.milestoneItems[i]).filter(
              (x, index) =>
                this.milestoneItems[i][x] &&
                this.milestoneItems[i][x] !== undefined &&
                String(this.milestoneItems[i][x]).trim().length > 0 &&
                this.milestoneItems[i][x] != 0
            ).length
          ) {
            let checkIndex = this.count.indexOf("milestoneItems");
            if (checkIndex < 0) {
              this.count.push("milestoneItems");
            }
          } else {
            countObj = 1;
            let checkIndex = this.count.indexOf("milestoneItems");
            if (checkIndex >= 0) {
              this.count.splice(checkIndex, 1);
            }
          }

          if (
            parseInt(this.milestoneItems[i]["paid_amount"]) >
            parseInt(this.milestoneItems[i]["value_number"])
          ) {
            checkNew = parseInt(checkNew) + 1;
          }
          amountPaild =
            parseFloat(amountPaild) +
            parseFloat(
              this.milestoneItems[i]["paid_amount"] == ""
                ? 0
                : this.milestoneItems[i]["paid_amount"]
            );
          value_amount_total =
            parseFloat(value_amount_total) +
            parseFloat(
              this.milestoneItems[i]["value_number"] == ""
                ? 0
                : this.milestoneItems[i]["value_number"]
            );
          if (countObj > 0) {
            // break;
          }
        }
        this.form.paid = amountPaild;

        if (this.formMouId > 0) {
          this.newCheckCount = this.newCheckCount + 1;
          if (this.newCheckCount > 2) {
            this.checkStatusEdit = false;
          }
        }
        let totalBalance =
          parseFloat(
            this.form.contractValue == "" ? 0 : this.form.contractValue
          ) +
          parseFloat(
            this.form.incremantalCost == "" ? 0 : this.form.incremantalCost
          );

        if (checkNew > 0) {
          this.errorValueAmount =
            "( Note: Paid Amount Should be less than Value )";
        } else if (this.form.totalBalance < 0) {
          this.errorValueAmount =
            "( Note: Balance Amount Cannot be less than 0 )";
        } else if (value_amount_total == totalBalance) {
          this.errorValueAmount = " ";
          console.log("Completed");
        } else if (value_amount_total > totalBalance) {
          this.errorValueAmount =
            "( Note: Milestone Value % Cannot be greater than 100% )";
        } else {
          this.errorValueAmount = "";
        }
      },
      deep: true,
    },
    optionItems: {
      handler: function (newVal, oldVal) {
        if (this.optionItems.length > 0) {
          console.log("this.count :>> ", this.count);

          let countObj = 0;
          for (let i = 0; i < this.optionItems.length; i++) {
            let len = 0;

            if (
              this.optionItems[i].hasOwnProperty("optionShow") &&
              this.optionItems[i].optionShow == false
            ) {
              len = Object.keys(this.optionItems[i]).length - 1;
            } else {
              len = Object.keys(this.optionItems[i]).length;
            }
            if (
              len ===
              Object.keys(this.optionItems[i]).filter(
                (x, index) =>
                  this.optionItems[i][x] &&
                  this.optionItems[i][x] !== undefined &&
                  String(this.optionItems[i][x]).trim().length > 0 &&
                  this.optionItems[i][x] != 0
              ).length
            ) {
              let checkIndex = this.count.indexOf("optionItems");
              if (checkIndex < 0) {
                this.count.push("optionItems");
                console.log("object :>> ", checkIndex);
              }
            } else {
              countObj = 1;
              let checkIndex = this.count.indexOf("optionItems");
              if (checkIndex >= 0) {
                this.count.splice(checkIndex, 1);
              }
            }
            if (countObj > 0) {
              break;
            }
          }

          if (this.formMouId > 0) {
            this.newCheckCount = this.newCheckCount + 1;
            if (this.newCheckCount > 2) {
              this.checkStatusEdit = false;
            }
          }
        } else {
          let i = this.count.indexOf("optionItems");
          if (i < 0) {
            this.count.push("optionItems");
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    organization_id() {
      return window.localStorage.getItem("OrganizationID");
    },

    checkSaveAsDraft() {
      if (
        this.form.projectName != "" &&
        this.form.company != "" &&
        this.form.vendorValue != "" &&
        this.form.typeOfAgreement != "" &&
        this.form.subcategoryValue != ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    checkSubmitStatus() {
      if (
        (this.formMouId == 0 && this.count.length == 3) ||
        (this.duplicate > 0 && this.count.length == 3)
      ) {
        return false;
      } else if (this.formMouId > 0 && this.duplicate == 0) {
        if (this.count.length != 3) {
          return true;
        } else {
          return this.checkStatusEdit;
        }
      } else {
        return true;
      }
    },
    disabledAllForm() {
      if (this.formMouId == 0 || this.duplicate > 0) {
        return false;
      } else if (this.formMouId > 0) {
        return this.form.mainFormShow == 1 ? false : true;
      }
    },
    tempContractUserListArrayNewList() {
      if (this.form.hierachyArray.length == 0) {
        return this.tempContractUserListArrayNew;
      } else {
        let arrayNew = this.tempContractUserListArrayNew;
        this.form.hierachyArray.map((y) => {
          y.user_list.map((z) => {
            let index = arrayNew.findIndex((x) => {
              return z.user_id == x.user_id && z.mou_map_isActive == 1;
            });

            if (index >= 0) {
              arrayNew.splice(index, 1);
            }
          });
        });

        // console.log(this.ContractUserList, "2w22222");

        return arrayNew;
      }
    },

    // isStartDateSelected: function{
    //   if()
    // }
  },
  mounted() {
    //  this.saveDraft();
    this.getAllContrcatFormData();
    eventBus.$on("contractTypeMou", (data) => {
      setTimeout(() => {
        this.form.typeOfAgreement = data;
      }, 2000);
    });
    // eventBus.$on("switchFormEditTab", data => {
    //   this.formMouId = data.ID;
    // });
    // console.log("this.formMouId :>> %%%%", this.formMouId);
  },
  beforeDestroy() {
    this.formMouId = 0;
    this.draft_id = 0;

    eventBus.$emit("switchTabMoudestroyThen", 0);
  },
  methods: {
    removeOptionList(removedOption, id) {
      console.log(removedOption);
      if (
        this.formMouId > 0 &&
        (removedOption.hasOwnProperty("mou_option_assignee_id") ||
          removedOption.hasOwnProperty("assignee_id"))
      ) {
        let index = 0;
        if (this.checkFormLevel == 1) {
          index = this.removeOptionListArray.findIndex((x) => {
            return (
              x.mou_option_assignee_id == removedOption.mou_option_assignee_id
            );
          });
          removedOption["showTypeLevel"] = 1;
          removedOption["checkIndex"] = this.optionItemsIndex;
        } else {
          index = this.removeOptionListArray.findIndex((x) => {
            return x.mou_option_assignee_id == removedOption.assignee_id;
          });
          removedOption["showTypeLevel"] = 2;
          removedOption["checkIndex"] = this.milestoneItemsIndex;
        }
        if (index < 0) {
          this.removeOptionListArray.push(removedOption);
        }
      }
      //console.log("dsass", this.removeOptionListArray);
    },

    // removeUserFromContract(removedOption, id) {
    //   console.log(removedOption, "fsfdsfsdf");
    //   this.tempContractUserListArrayNew.push(removedOption);
    //   console.log(this.tempContractUserListArrayNew, removedOption);

    //   console.log(removedOption, id);
    // },
    addNewLevel() {
      this.saveUserIndex = "";
      this.userLevelArray = [];
      this.addUserLevel = true;

      if (this.form.hierachyArray.length > 0) {
        //this.MainUserLevel = 0;
        let newArray =
          this.form.hierachyArray[this.form.hierachyArray.length - 1][
            "user_level"
          ] + 1;
        this.MainUserLevel = newArray;
      } else {
        this.MainUserLevel = 1;
      }
      this.UserPopupTitle = "Level " + this.MainUserLevel;
    },
    addUserLevelMet() {
      if (this.userLevelArray.length > 0) {
        if (this.saveUserIndex == "") {
          this.userLevelArray.map((x) => {
            x["mou_map_isActive"] = 1;
          });
          let obj = {
            user_level: this.MainUserLevel,
            user_list: this.userLevelArray,
          };

          this.form.hierachyArray.push(obj);
        } else {
          this.userLevelArray.map((x) => {
            x["mou_map_isActive"] = 1;

            this.form.hierachyArray[this.saveUserIndex - 1]["user_list"].push(
              x
            );
          });
        }
      }

      // console.log(this.form.hierachyArray, "fsdfdsfsdfs");

      setTimeout((x) => {
        this.userLevelArray = [];
        //  alert("gdgdfg");
      }, 1000);
      this.addUserLevel = false;
    },
    onProjectSelect(selected) {
      console.log(selected);
      Object.keys(this.projectObj).map((x) => {
        let val = this.projectObj[x];
        this.form[val] = selected[x];
      });
      this.getAllCategoryListByProject(selected.project_id);
    },
    getAllCategoryListByProject: function (id) {
      CategoryService.getProjectMainCategories(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoryList = data.data;
            if (this.formMouId > 0) {
              let categoryArray = this.categoryList.filter((x) => {
                return x.category_id == this.form.categoryValue;
              });
              this.form.categoryValue = categoryArray[0];
            }
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    getBalanceAmount: function () {
      this.form.balanceAmount =
        parseFloat(
          this.form.contractValue == "" ? 0 : this.form.contractValue
        ) +
        parseFloat(
          this.form.incremantalCost == "" ? 0 : this.form.incremantalCost
        ) -
        parseFloat(this.form.paid == "" ? 0 : this.form.paid);

      this.milestoneItems.map((x, index) => {
        this.getValueNumberAmount(index);
      });
    },
    getValueNumberAmount: function (index) {
      this.milestoneItems[index].value = parseFloat(
        (parseFloat(this.milestoneItems[index].value_number) /
          (parseFloat(this.form.contractValue) +
            parseFloat(this.form.incremantalCost))) *
          100
      ).toFixed(1);
    },
    addUserToLevel: function (level, index) {
      this.addUserLevel = true;
      this.userLevelArray = [];
      if (this.formMouId > 0) {
        level["user_list"].map((x) => {
          if (x.mou_map_isActive == 1) {
            let array = this.ContractUserList.filter((y) => {
              return x.user_id == y.user_id;
            });

            // this.userLevelArray.push(array[0]);
          }
        });
      } else {
        // this.userLevelArray = level["user_list"];
      }
      // this.saveUserLevel = level;
      this.saveUserIndex = index + 1;
      // console.log(this.saveUserIndex, index);
      this.UserPopupTitle = "Level " + level.user_level;
    },
    removeUserFromLevel: function (index1, index2) {
      this.removeUserPop = true;
      this.removeUserLevel1 = index1;
      this.removeUserLevel2 = index2;

      this.UserRemoveName =
        this.form.hierachyArray[index1]["user_list"][index2]["user_name"];
      this.UserRemoveLevel = this.form.hierachyArray[index1]["user_level"];
    },
    onChangeRmove: function () {
      this.removeUserPop = false;
      if (this.formMouId > 0 && this.duplicate == 0) {
        let array = this.ContractUserList.filter((x) => {
          return (
            x.user_id ==
            this.form.hierachyArray[this.removeUserLevel1]["user_list"][
              this.removeUserLevel2
            ]["user_id"]
          );
        });
        this.tempContractUserListArrayNew.unshift(array[0]);
        this.form.hierachyArray[this.removeUserLevel1]["user_list"][
          this.removeUserLevel2
        ]["mou_map_isActive"] = 3;
      } else {
        this.tempContractUserListArrayNew.unshift(
          this.form.hierachyArray[this.removeUserLevel1]["user_list"][
            this.removeUserLevel2
          ]
        );
        this.form.hierachyArray[this.removeUserLevel1]["user_list"].splice(
          this.removeUserLevel2,
          1
        );

        //this.form.hierachyArray[this.removeUserLevel1]["user_list"].push()
      }

      if (
        this.form.hierachyArray[this.removeUserLevel1]["user_list"].length == 0
      ) {
        this.form.hierachyArray.splice(this.removeUserLevel1, 1);
      }
    },
    privStatusLevel: function () {
      this.removeUserPop = false;
    },

    onChangeCheckStatus: function (data, index) {
      this.testOptionStatus = data;
      this.testOptionIndex = index;
      this.deliverablesStatus = true;
    },
    onChangeOptionCheckStatus: function () {
      this.deliverablesStatus = false;
    },
    privDeliverablesStatus: function () {
      this.optionItems[this.testOptionIndex].option_status =
        this.testOptionStatus;

      this.deliverablesStatus = false;
    },
    newForm: function () {
      this.clearForm();
    },
    getAllFormDetails: function (ID) {
      this.$vs.loading();
      // call api
      ContractService.getSignleMouFormDetails(ID)
        .then((response) => {
          this.$vs.loading.close();

          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            console.log("data.data :>>*******&&&&&&&******* ", data.data);

            Object.keys(data.data[0]).map((x) => {
              //    console.log("x xxxxxxxxxxxx:>> ", x, data.data[0][x]);
              this.form[x] = data.data[0][x];
            });
            this.getAllCategoryListByProject(data.data[0].projectName);
            this.getSubcategoryList(
              data.data[0].categoryValue,
              data.data[0].projectName
            );
            // let arrayNew = this.tempContractUserListArrayNew;

            // this.form.hierachyArray.map(y => {
            //   y.user_list.map(z => {
            //     let index = arrayNew.findIndex(x => {
            //       return z.user_id == x.user_id && z.mou_map_isActive == 1;
            //     });
            //     console.log(index, "fsdfdsfsd");
            //     if (index >= 0) {
            //       arrayNew.splice(index, 1);
            //     }
            //   });
            // });

            // this.tempContractUserListArrayNew = arrayNew;

            if (data.data[0].uploadDocument != "-") {
              this.isDocuments = data.data[0].uploadDocument.split(",");
            }

            // this.uploadedDocument = this.form["uploadDocument"];

            let typeOfAgreementArray = this.formType.filter((x) => {
              return x.id == this.form.typeOfAgreement;
            });
            this.form.typeOfAgreement = typeOfAgreementArray[0];

            let vendorArray = this.vendorList.filter((x) => {
              return x.vendor_id == this.form.vendorValue;
            });
            this.form.vendorValue = vendorArray[0];

            let companyArray = this.companyList.filter((x) => {
              return x.company_id == this.form.companyValue;
            });
            this.form.companyValue = companyArray[0];

            // let categoryArray = this.categoryList.filter(x => {
            //   return x.category_id == this.form.categoryValue;
            // });
            // this.form.categoryValue = categoryArray[0];

            let priorityArray = this.priority.filter((x) => {
              return x.value == this.form.priorityValue;
            });
            this.form.priorityValue = priorityArray[0];

            let projectNameArray = this.projectList.filter((x) => {
              //  console.log(x.contract_project_id, "fsdfdsf");
              return x.project_id == this.form.projectName;
            });
            //  console.log(this.projectList, projectNameArray, "fsdfsdfsdf");
            this.form.projectName = projectNameArray[0];

            // let projectTypeArray = this.ProjectTypeList.filter(x => {
            //   return x.project_type_id == this.form.projectTypeValue;
            // });
            // this.form.projectTypeValue = projectTypeArray[0];

            // let projectTypeArray = this.ProjectTypeList.filter(x => {
            //   return x.project_type_id == this.form.projectTypeValue;
            // });
            // this.form.projectTypeValue = projectTypeArray[0];

            // let showTypeArray = this.showTypeList.filter(x => {
            //   return x.show_type_id == this.form.showTypeValue;
            // });
            // this.form.showTypeValue = showTypeArray[0];

            let ContractStatusArray = this.ContractStatusList.filter((x) => {
              return x.status_id == this.form.ContractStatusValue;
            });

            this.form.ContractStatusValue = ContractStatusArray[0];

            let langList = this.form.languageValue.split(",");
            this.form.languageValue = [];
            langList.map((y) => {
              let languageArray = this.languageList.filter((x) => {
                return x.language_id.toString() == y.toString();
              });
              console.log(languageArray, y);
              this.form.languageValue.push(languageArray[0]);
            });

            let platformList3 = this.form.contractPlatformValue.split(",");
            this.form.contractPlatformValue = [];
            platformList3.map((y) => {
              let platformArray = this.platformList.filter((x) => {
                return x.platform_id.toString() == y.toString();
              });

              this.form.contractPlatformValue.push(platformArray[0]);
            });

            this.milestoneItems = [];
            data.data[0].milestoneItems.map((x, index) => {
              x["assignee"] = [];
              x["add"] = 1;

              if (x.Assignee.length > 0) {
                x.Assignee.map((z) => {
                  let userArray = this.ContractUserList.filter((y) => {
                    return z.user_id == y.user_id;
                  });
                  if (userArray.length > 0) {
                    userArray[0]["assignee_id"] = z.assignee_id;
                    x["assignee"].push(userArray[0]);
                  }
                });
              } else {
                x.Assignee = ["fsdfds"];
              }
              this.milestoneItems.push(x);
            });

            this.optionItems = [];

            if (data.data[0].optionItems.length > 0) {
              data.data[0].optionItems.map((x, index) => {
                x["assignee"] = [];
                x["add"] = 1;

                if (x.Assignee.length > 0) {
                  x.Assignee.map((z) => {
                    let userArray = this.ContractUserList.filter((y) => {
                      return z.user_id == y.user_id;
                    });
                    if (userArray.length > 0) {
                      userArray[0]["mou_option_assignee_id"] =
                        z.mou_option_assignee_id;
                      x["assignee"].push(userArray[0]);
                    }
                  });
                } else {
                  x.Assignee = ["Test"];
                }
                this.optionItems.push(x);
              });
            }
            setTimeout((x) => {
              window.scrollTo(0, 0);
              //  alert("gdgdfg");
            }, 500);
            let countHierachy = -1;
            this.form.mainFormShow = 2;
            this.form.hierachyArray.map((x, index) => {
              if (x.user_level == this.form.activeHierarchyLevel) {
                countHierachy = x.user_list.findIndex((y) => {
                  return y.user_id == this.form.loggedInUserId;
                });

                if (countHierachy >= 0) {
                  this.form.hierachyArray[index]["user_list"][countHierachy][
                    "approved_status"
                  ] = 2;
                  this.form.mainFormShow = 1;
                  //console.log("fsdfdsfs");
                  this.checkStatusEdit = false;
                } else {
                  this.form.mainFormShow = 2;
                }

                console.log(countHierachy);
              }
            });

            if (
              this.form.mou_created_by == this.form.loggedInUserId ||
              this.duplicate > 0
            ) {
              this.form.mainFormShow = 1;
              this.checkStatusEdit = false;
            } else {
            }

            if (this.duplicate > 0) {
              this.form["isDuplicate"] = true;
              this.form["hierachyArray"] = [];

              this.form.showHierarchy = true;

              // this.checkStatusEdit = false;
            }

            // console.log(countHierachy, "fsfsdfsdfssssssss");

            console.log(this.form.hierachyArray, this.form.mainFormShow);

            // this.milestoneItems = data.data[0].milestoneItems;
            // this.optionItems = data.data[0].optionItems;
          } else {
            this.$vs.notify({
              title: "Data not found",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data not found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    submitAndSave: function () {
      if (this.formMouId > 0 && this.duplicate == 0) {
        this.contractFormReason = true;
      } else {
        this.submitForm();
      }
    },

    getDraftList() {
      this.$vs.loading();
      let filters = {
        draft_id: this.draft_id,

        // contractType: "Agreement"
      };
      this.draftList = [];

      ContractService.getDraft(filters)
        .then((response) => {
          const { data } = response;
          console.log(data.data, "draftData");
          if (!data.err) {
            data.data.map((x) => {
              let obj = JSON.parse(x.draft);
              obj["draft_id"] = this.draft_id;
              this.uploadedDocument = "-";
              this.form = obj;
              this.optionItems = obj.optionItems;
              if (obj["uploadDocument"] != "-") {
                this.isDocuments = obj["uploadDocument"].split(",");
              }
              delete obj["uploadDocument"];
              this.milestoneItems = obj.milestoneItems;
            });

            this.getAllCategoryListByProject(this.form.projectName.project_id);
            this.getSubcategoryList(
              this.form.categoryValue.category_id,
              this.form.projectName.project_id
            );
            // data.data.map(x => {
            //   let obj = JSON.parse(x.draft);
            //   obj["draft_id"] = x["draft_id"];
            //   obj["mou_created_by_name"] = x["draft_created_by"];
            //   obj["mou_created_date"] = x["draft_created_date"];
            //   console.log(obj);
            //   this.draftList.push(obj);
            // });
            //   this.formType = data.data;
            // this.totalPages = data.pagination.last_page;
          } else {
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveDraft() {
      this.$vs.loading();
      this.form["optionItems"] = this.optionItems;
      this.form["milestoneItems"] = this.milestoneItems;
      this.form["contract_project_name"] = this.form.projectName.project_name;
      this.form["company_name"] = this.form.companyValue.company_name;
      this.form["vendor_name"] = this.form.vendorValue.vendor_name;
      this.form["category_name"] = this.form.categoryValue.category_name;
      this.form["sub_category_name"] = this.form.subcategoryValue.category_name;
      if (this.uploadedDocument == "-" && this.isDocuments.length > 0) {
        this.form["uploadDocument"] = this.isDocuments.join(",");
      } else if (this.isDocuments.length > 0 && this.uploadedDocument != "-") {
        this.form["uploadDocument"] =
          this.uploadedDocument + "," + this.isDocuments.join(",");
      } else {
        this.form["uploadDocument"] = this.uploadedDocument;
      }

      //      this.form.vendorValue = this.form.vendorValue.vendor_id;
      // this.form.companyValue = this.form.companyValue.company_id;
      // this.form.contractPlatformValue = this.form.contractPlatformValue.platform_id;
      // this.form.categoryValue = this.form.categoryValue.category_id;
      // this.form.subcategoryValue = this.form.subcategoryValue.sub_category_id;
      // this.form.priorityValue = this.form.priorityValue.value;
      // // this.form.projectTypeValue = this.form.projectTypeValue.project_type_id;
      // // this.form.showTypeValue = this.form.showTypeValue.show_type_id;
      // this.form.projectName = this.form.projectName.contract_project_id;
      // this.form.ContractStatusValue = this.form.ContractStatusValue.status_id;
      // this.form.languageValue = this.form.languageValue.language_id;

      let payload = {
        typeOfAgreement: this.form.typeOfAgreement.id,
        draft: JSON.stringify(this.form),
        contract_type: this.form.typeOfAgreement.contract_type,
      };

      if (this.draft_id > 0) {
        payload["draft_id"] = this.draft_id;
      }

      ContractService.addDraft(payload)
        .then((response) => {
          console.log("response :>> ", response);
          this.submitStatus = false;
          const { data } = response;

          this.$vs.loading.close();
          // console.log("fdsfdsf");
          if (data.Status == true) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });

            // eventBus.$emit("refreshTable", data);
          }
          this.clearForm();
        })
        .catch((error) => {
          this.submitStatus = true;
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    closeModalnew: function () {
      this.contractFormReason = false;
      this.reason = "";
    },
    getAllUplodeFiles: function () {
      console.log("this.is :>> ", this.isDocuments);
      this.uploadFilePopup = true;
    },

    onChangeCheckUploadFile: function (doc) {
      window.open(doc + "?token=" + this.temp_token);
    },
    onChangeDeleteFile: function (index) {
      this.isDocuments.splice(index, 1);
    },

    submitForm: function () {
      if (!this.reason && this.formMouId > 0 && this.duplicate == 0) {
        this.$vs.notify({
          title: "Warning",
          text: "Add reason before submit the form",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
        return false;
      }
      this.contractFormReason = false;
      this.$vs.loading();

      this.submitStatus = true;
      this.form.typeOfAgreement = this.form.typeOfAgreement.id;
      this.form.vendorValue = this.form.vendorValue.vendor_id;
      this.form.companyValue = this.form.companyValue.company_id;
      // this.form.contractPlatformValue = this.form.contractPlatformValue.platform_id;
      this.form.categoryValue = this.form.categoryValue.category_id;
      this.form.subcategoryValue = this.form.subcategoryValue.category_id;
      this.form.priorityValue = this.form.priorityValue.value;

      // this.form.projectTypeValue = this.form.projectTypeValue.project_type_id;
      // this.form.showTypeValue = this.form.showTypeValue.show_type_id;
      this.form["contract_budgetactive"] =
        this.form.projectName.contract_budgetactive;
      this.form.projectName = this.form.projectName.project_id;
      this.form["status_budgetactive"] =
        this.form.ContractStatusValue.budget_isActive;
      this.form.ContractStatusValue = this.form.ContractStatusValue.status_id;

      let languageVal = "";
      this.form.languageValue.map((x) => {
        languageVal += x.language_id + ",";
      });
      languageVal = languageVal.substring(0, languageVal.length - 1);
      this.form.languageValue = languageVal;

      let contractPlatformVal = "";
      this.form.contractPlatformValue.map((x) => {
        contractPlatformVal += x.platform_id + ",";
      });
      contractPlatformVal = contractPlatformVal.substring(
        0,
        contractPlatformVal.length - 1
      );
      this.form.contractPlatformValue = contractPlatformVal;

      if (this.uploadedDocument == "-" && this.isDocuments.length > 0) {
        this.form["uploadDocument"] = this.isDocuments.join(",");
      } else if (this.isDocuments.length > 0 && this.uploadedDocument != "-") {
        this.form["uploadDocument"] =
          this.uploadedDocument + "," + this.isDocuments.join(",");
      } else {
        this.form["uploadDocument"] = this.uploadedDocument;
      }

      if (this.formMouId > 0) {
        this.form["reason"] = this.reason;
      }
      setTimeout((x) => {
        let payload = {
          ...this.form,
          optionItems: this.optionItems,
          milestoneItems: this.milestoneItems,
        };
        // console.log(payload);
        // return false;
        // console.log("payload :>> ", payload);
        ContractService.addMouForm(payload)
          .then((response) => {
            //console.log("response :>> ", response);
            this.submitStatus = false;
            const { data } = response;

            this.$vs.loading.close();
            //  console.log("fdsfdsf");
            if (data.Status == true) {
              this.$vs.notify({
                title: "Updated!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });

              // eventBus.$emit("refreshTable", data);
            }
            this.clearForm();
          })
          .catch((error) => {
            this.submitStatus = true;
            // this.$vs.notify({
            //   title: "Error!",
            //   text: error.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
          });
      }, 1000);
    },

    getAllContrcatFormData: function () {
      ContractService.getAllContractData()
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.formType = data.data[0].recordsAgreementMOU;
            this.platformList = data.data[0].recordsContractPlatform;
            this.vendorList = data.data[0].recordsContractVendor;
            this.projectList = data.data[0].recordsProject;
            this.companyList = data.data[0].recordsContractCompany;
            // this.categoryList = data.data[0].recordsContractCategory;
            this.ProjectTypeList = data.data[0].recordsContractProjectType;
            this.ContractStatusList = data.data[0].recordsContractStatus;
            this.showTypeList = data.data[0].recordsContractShowType;
            this.languageList = data.data[0].recordsContractLanguage;
            this.ReminderTypeList = data.data[0].recordsContractReminderType;
            this.ContractUserList = data.data[0].recordsContractUser;
            this.tempContractUserListArrayNew =
              data.data[0].temprecordsContractUser;

            //  console.log(this.ContractUserList, "fdsfdsfsdf");

            setTimeout((x) => {
              //  console.log(data.data, "122233", this.formMouId);
              if (this.formMouId > 0) {
                // console.log(data.data, "fdsfdsfdsfsdf");
                this.getAllFormDetails(this.formMouId);
              } else {
                let array = data.data[0].recordsContractStatus.filter((x) => {
                  return x.status_name.includes("Under Draft");
                });

                // console.log(array, "fsdfdsfdsfdsf");

                if (array.length > 0) {
                  this.form.ContractStatusValue = array[0];
                }
              }

              if (this.draft_id > 0) {
                this.getDraftList();
              }
            }, 1000);
          } else {
            this.$vs.notify({
              title: "Data not found",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data not found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onSuggestSelect: function (selected) {
      this.getSubcategoryList(
        selected.category_id,
        this.form.projectName.project_id
      );
    },

    getSubcategoryList: function (ID, project_id) {
      // let pCateId = this.categoryValue.category_id;
      let pId = project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategoryList = data.data;
            if (this.formMouId > 0) {
              let subcategoryArray = this.subcategoryList.filter((x) => {
                return x.category_id == this.form.subcategoryValue;
              });
              this.form.subcategoryValue = subcategoryArray[0];
            }
          } else {
            this.$vs.notify({
              title: "Error!",
              text: "No Data Found",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: "No Data Found",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    addAssignee: function (index) {
      this.mileStoneArray = this.milestoneItems[index]["assignee"];
      this.milestoneItemsIndex = index;
      this.addAssigneePopup = true;
      this.checkFormLevel = 2;
    },
    optionAddAssignee: function (index) {
      this.optionArray = this.optionItems[index]["assignee"];
      this.optionItemsIndex = index;
      this.optionAssigneePopup = true;
      this.checkFormLevel = 1;
    },
    addOptionData: function (type) {
      if (type === "optionDateData") {
        let optionDateObj = {
          add: "1",
          option_description: "",
          schedule_date: "",
          actual_date: "",
          option_status: "1",
          reminder_type: "Select Option",
          optionShow: true,
          assignee: [],
        };
        this.optionItems.push(optionDateObj);
      } else if (type === "milestoneData") {
        let milestoneObj = {
          add: "1",
          contract_description: "",
          value: null,
          value_number: null,
          paid_amount: 0,
          milestoneShow: true,
          due_date: "",
          reminder_type: "Select Option",

          assignee: [],
        };
        this.milestoneItems.push(milestoneObj);
      }
    },
    removeOptionData: function (index, type) {
      this.rowIndex = {
        index: index,
        type: type,
      };
      this.popupActive = true;
    },
    onChangeCheckbox: function () {
      if (this.rowIndex.type === "optionDateData") {
        this.optionItems.splice(this.rowIndex.index, 1);
      } else if (this.rowIndex.type === "milestoneData") {
        this.milestoneItems.splice(this.rowIndex.index, 1);
      }
      this.rowIndex = {};
      this.popupActive = false;
    },
    privStatus: function () {
      this.popupActive = false;
      this.rowIndex = {};
    },
    onChangeCheckOptionAssignee: function () {
      this.optionItems[this.optionItemsIndex].assignee = this.optionArray;
      this.optionArray = [];
      this.optionAssigneePopup = false;
    },
    onChangeCheckAssignee: function () {
      this.milestoneItems[this.milestoneItemsIndex].assignee =
        this.mileStoneArray;
      this.mileStoneArray = [];
      this.addAssigneePopup = false;
    },

    clearFile: function () {
      this.fileUpload.deleteButtonStatus = false;
      this.$refs.myVueDropzoneFile.enable();
      this.$refs.myVueDropzoneFile.removeAllFiles();
      this.uploadedDocument = "-";
    },
    vdropzoneFilesAdded: function (file) {
      console.log("file 1 :", file);
    },

    vdropzoneSuccessFile: function (file, response) {
      this.uploadedDocument = response.data.image_path;

      this.fileUpload.deleteButtonStatus = true;
      this.$refs.myVueDropzoneFile.disable();
    },
    vdropzoneComplete: function (response) {
      console.log("response :", response);
    },
    vdropzoneError: function (file, message, xhr) {
      console.log("file, message, xhr :", file, message, xhr);
    },
    resetForm: function () {
      this.clearForm();
    },
    clearForm: function () {
      this.form = {
        mou_isactive: 1,
        typeOfAgreement: "",
        vendorValue: "",
        projectName: "",
        companyValue: "",
        mainFormShow: 1,

        categoryValue: "",
        subcategoryValue: "",
        priorityValue: "",

        projectTypeValue: "",
        showTypeValue: "",
        ContractStatusValue: "",

        dateFrom: "",
        dateTo: "",
        languageValue: "",

        directorName: "",
        writerName: "",
        internalCD: "",

        leadCast: "",
        episodes: "",
        projectDuration: "",
        showHierarchy: true,

        shootDays: "",

        inrRate: 1,
        episodeCost: 1,
        budgetAmt: 0,

        contractValue: 0,
        incremantalCost: 0,
        paid: 0,

        balanceAmount: 0,

        content: "",
        hierachyArray: [],
        budget_id: 0,
      };
      this.reason = "";
      this.uploadedDocument = "-";
      this.optionItems = [
        // {
        //   add: "1",
        //   option_description: "",
        //   schedule_date: "",
        //   actual_date: "",
        //   option_status: "1",
        //   reminder_type: "Select Option",
        //   assignee: []
        // }
      ];
      this.milestoneItems = [
        {
          add: "1",
          contract_description: "",
          value: null,
          value_number: null,
          paid_amount: 0,
          due_date: "",
          reminder_type: "Select Option",
          //  reminder: 0,
          assignee: [],
        },
      ];
      this.formMouId = 0;
      this.clearFile();
      eventBus.$emit("clearAllComment");
    },
  },
};
</script>

<style>
</style>
